/*
	Prevent animations during resize
 */

var rtime;
var rtimeout = false;
var rdelta = 100;
var body = document.body;

function resizeend() {
    if (new Date() - rtime < rdelta) {
        setTimeout(resizeend, rdelta);
    } else {
        rtimeout = false;
        body.classList.remove('noanim');
    }
}

window.addEventListener('resize', function () {
    rtime = new Date();
    if (rtimeout === false) {
        rtimeout = true;
        setTimeout(resizeend, rdelta);
    }
});

document.addEventListener('DOMContentLoaded', function () {
    window.addEventListener('resize', function () {
        body.classList.add('noanim');
    });
});
