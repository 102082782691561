var hamburger = document.querySelector('.hamburger'),
    menu = document.querySelector('.navigation'),
    header = document.querySelector('.header'),
    body = document.body

//Toggle hamburger menu
function toggleMenu() {
    hamburger.classList.toggle('hamburger--open');
    menu.classList.toggle('navigation--open');
    header.classList.toggle('menu-open');
    body.classList.toggle('menu-open');
}

hamburger.addEventListener('click', toggleMenu);

//Add arrow if item has a submenu
document.querySelectorAll('.navigation > ul > li').forEach(function (element) {
    if (element.querySelector('.submenu')) {
        var clickableArrow = document.createElement('span');

        clickableArrow.addEventListener('click', function (e) {
            e.preventDefault();
            element.classList.toggle('sub-open');
            document.querySelector('.submenu').classList.toggle('sub-open');
            hamburger.classList.toggle('hamburger--sub-open');
        });
        element.insertBefore(clickableArrow, element.querySelector('.submenu'));
    }
});

//Toggle language selector
var languageSelector = document.querySelectorAll('.language-selector');
var languageToggler = document.querySelectorAll('.js-open-languages');

if (languageToggler) {
    languageToggler.forEach(function (el) {
        el.addEventListener('click', function () {
            this.parentElement.classList.toggle('open');
        });
    });
}

//Show/hide navbar on scroll
var headerElement = document.querySelector('.header');
var c, currentScrollTop = 0;

window.onscroll = function () {
    var a = window.pageYOffset;
    var headerHeight = headerElement.offsetHeight / 2;

    currentScrollTop = a;

    if (c < currentScrollTop && a > headerHeight) {
        headerElement.classList.add('isScrolled');
        headerElement.classList.remove('isTop');
    } else if (c > currentScrollTop && !(a <= headerHeight)) {

    } else {
        headerElement.classList.add('isTop');
        headerElement.classList.remove('isScrolled');
    }

    if (c < currentScrollTop && a > 5) {
        headerElement.classList.remove('showBreadcrumb');
    } else if (c > currentScrollTop && !(a <= headerHeight)) {

    } else {
        headerElement.classList.add('showBreadcrumb');
    }
    c = currentScrollTop;
}
