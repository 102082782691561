document.addEventListener('DOMContentLoaded', function () {
    //Open modal based on it's ID
    document.querySelectorAll('.js-openModal').forEach(function (element) {
        element.addEventListener('click', toggleModal);
    });

    //Close modal based on it's ID
    document.querySelectorAll('.js-closeModal').forEach(function (element) {
        element.addEventListener('click', closeModal);
    });

    //Hide modal when clicking outside
    document.querySelectorAll('.modal').forEach(function (element) {
        element.addEventListener('click', function (e) {
            if (!e.target.closest('.modal__inner')) {
                element.classList.remove('modal-open');
                document.body.classList.remove('modal-open');
            }
        });
    });

    function toggleModal() {
        var modalId = this.getAttribute('data-id');
        var modal = document.getElementById(modalId);

        modal.classList.toggle('modal-open');
        document.body.classList.toggle('modal-open');
    }

    function closeModal() {
        var modalId = this.getAttribute('data-id');
        var modal = document.getElementById(modalId);

        modal.classList.remove('modal-open');
        document.body.classList.remove('modal-open');
    }
});
