clickableBlock = document.querySelectorAll('.clickable');

if (clickableBlock) {
    clickableBlock.forEach(function (el) {
        el.addEventListener('click', function (e) {
            var url = this.getElementsByClassName('url')[0].href;
            window.open(url,"_self");
        });
    });
}

copyUrl = document.querySelectorAll('.copyurl');

if (copyUrl) {
    copyUrl.forEach(function (el) {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            copyText(this);
        });
    });
}

function copyText(element) {
    var textToCopy = element.href;
    var myTemporaryInputElement = document.createElement("input");
    myTemporaryInputElement.type = "text";
    myTemporaryInputElement.value = textToCopy;
    document.body.appendChild(myTemporaryInputElement);
    myTemporaryInputElement.select();
    document.execCommand("Copy");
    document.body.removeChild(myTemporaryInputElement);
}

