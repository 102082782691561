document.addEventListener('DOMContentLoaded', function () {
    var jsScrollTo = document.querySelectorAll('.js-scroll');

    jsScrollTo.forEach(function (el) {
        el.addEventListener('click', function () {
            document.getElementById(el.getAttribute('data-anchor')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
});
