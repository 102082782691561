import {Splide} from '@splidejs/splide';

// Quote slider
let quoteSlider = document.getElementsByClassName('quote-slider');
let quoteSliderNav = document.getElementsByClassName('quote-slider-nav');

document.addEventListener('DOMContentLoaded', function () {
    if (quoteSlider.length > 0) {
        for (let i = 0; i < quoteSlider.length; i++) {
            let main = new Splide(quoteSlider[i], {
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: true,
                width: '100%'
            });

            let nav = new Splide(quoteSliderNav[i], {
                rewind: true,
                pagination: false,
                arrows: false,
                isNavigation: true,
                width: '100%',
                fixedWidth: 96,
                gap: 0,
                breakpoints: {
                    640: {
                        fixedWidth: 48,
                        gap: 8,
                    }
                }
            });

            let bar = main.root.querySelector('.splide-progress-bar');

            main.on('mounted move', function () {
                let end = main.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (main.index + 1) / end) + '%';
            });

            main.sync(nav)
            main.mount();
            nav.mount();
        }
    }
});

//image slider
let imageSlider = document.getElementsByClassName('image-slider');

document.addEventListener('DOMContentLoaded', function () {
    if (imageSlider.length > 0) {
        for (let i = 0; i < imageSlider.length; i++) {
            let main = new Splide(imageSlider[i], {
                type: 'slide',
                perPage: 1,
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: true,
                width: '100%',
                gap: 32,
                breakpoints: {
                    640: {
                        gap: 4,
                    }
                }
            });

            let bar = main.root.querySelector('.splide-progress-bar');

            main.on('mounted move', function () {
                let end = main.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (main.index + 1) / end) + '%';
            });

            let track = main.root.querySelector('.splide__track');
            track.classList.add('hasOverflow');

            main.mount();
        }
    }
});

//image three columns
let imageThreeColumns = document.getElementsByClassName('image-three-columns-slider');

document.addEventListener('DOMContentLoaded', function () {
    if (imageThreeColumns.length > 0) {
        for (let i = 0; i < imageThreeColumns.length; i++) {
            let main = new Splide(imageThreeColumns[i], {
                type: 'slide',
                perPage: 3,
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: true,
                width: '100%',
                gap: 8,
                breakpoints: {
                    768: {
                        perPage: 2,
                    },
                    640: {
                        perPage: 1,
                    }
                }
            });

            let bar = main.root.querySelector('.splide-progress-bar');

            main.on('mounted move', function () {
                let end = main.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (main.index + 1) / end) + '%';
            });

            let track = main.root.querySelector('.splide__track');
            track.classList.add('hasOverflow');

            main.mount();
        }
    }
});

//image four columns
let imageFourColumns = document.getElementsByClassName('image-four-columns-slider');

document.addEventListener('DOMContentLoaded', function () {
    if (imageFourColumns.length > 0) {
        for (let i = 0; i < imageFourColumns.length; i++) {
            let main = new Splide(imageFourColumns[i], {
                type: 'slide',
                perPage: 4,
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: true,
                width: '100%',
                gap: 0,
                breakpoints: {
                    1024: {
                        perPage: 3,
                    },
                    640: {
                        perPage: 2,
                    }
                }
            });

            let bar = main.root.querySelector('.splide-progress-bar');

            main.on('mounted move', function () {
                let end = main.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (main.index + 1) / end) + '%';
            });

            let track = main.root.querySelector('.splide__track');
            track.classList.add('hasOverflow');

            main.mount();
        }
    }
});
