vacancyClick = document.querySelectorAll('.js-vacancy-click');
window.dataLayer = window.dataLayer || [];

if (vacancyClick) {
    vacancyClick.forEach(function (el, key) {
        el.addEventListener('click', function (e) {
            window.dataLayer.push({
                'event': 'click',
                'vacancy': [{
                    'name': el.getAttribute('data-name'),
                    'salary': el.getAttribute('data-salary'),
                    'employment': el.getAttribute('data-employment')
                }]
            });
        });
    });
}
