import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';

document.addEventListener('DOMContentLoaded', function () {
    gsap.registerPlugin(ScrollTrigger);

    var self = this;
    var jsBlockHeroHome = Array.prototype.slice.call(document.querySelectorAll('.block_hero_home'));

    jsBlockHeroHome.forEach(function (self) {
        gsap.to(self.querySelector('.title_prefix'), {scrollTrigger: self, y: 0, opacity: 1, delay: .5});
        gsap.to(self.querySelector('.heading-h1'), {scrollTrigger: self, y: 0, opacity: 1, delay: .8});
        gsap.to(self.querySelector('p'), {scrollTrigger: self, y: 0, opacity: 1, delay: 1.1});
        gsap.to(self.querySelector('.button--primary'), {scrollTrigger: self, opacity: 1, delay: 1.5});
        gsap.to(self.querySelector('.button--tertiary'), {scrollTrigger: self, opacity: 1, delay: 1.5});

        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: self,
                start: 'top top',
                end: 'bottom center-=25%',
                pin: false,
                scrub: true
            }
        });

        if (self.querySelector('picture')) {
            tl.to(self.querySelector('picture'), {y: 200, duration: 2});
        }
        if (self.querySelector('video')) {
            tl.to(self.querySelector('video'), {y: 200, duration: 2});
        }

    });

    var heroMultiplusColumnsAnimation = Array.prototype.slice.call(document.querySelectorAll('.block_hero_multiple_columns .item'));

    heroMultiplusColumnsAnimation.forEach(function (self) {
        gsap.to(self.querySelector('.heading-h1'), {scrollTrigger: self, y: 0, opacity: 1, delay: .8});
        gsap.to(self.querySelector('p'), {scrollTrigger: self, y: 0, opacity: 1, delay: 1.1});

        ScrollTrigger.matchMedia({
            // desktop
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top top',
                        end: 'bottom center-=25%',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('picture')) {
                    tl.to(self.querySelector('picture'), {y: 200, duration: 2});
                }
            }
        });
    });

    var imageTextSmall = Array.prototype.slice.call(document.querySelectorAll('.block_image_text_small'));

    imageTextSmall.forEach(function (self) {
        ScrollTrigger.matchMedia({
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top top',
                        end: 'bottom center-=25%',
                        pin: false,
                        scrub: true
                    }
                });

                var animate = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: '+=133 50%',
                        end: '+=200 20%',
                        pin: false,
                        scrub: true,
                        once: true
                    }
                });

                animate.to(self.querySelector('.heading-h2'), {y: 0, opacity: 1});
                animate.to(self.querySelector('p'), {y: 0, opacity: 1});
                animate.to(self.querySelector('ul'), {y: 0, opacity: 1});
                animate.to(self.querySelector('.cta'), {y: 0, opacity: 1});
                tl.to(self.querySelector('figure'), {y: 100});
            },
            '(min-width: 768px) and (max-width: 1023px)': function () {
                var animate = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'center center',
                        end: 'center 20%',
                        pin: false,
                        scrub: true,
                        once: true
                    }
                });

                animate.to(self.querySelector('.heading-h2'), {y: 0, opacity: 1});
                animate.to(self.querySelector('p'), {y: 0, opacity: 1});
                animate.to(self.querySelector('ul'), {y: 0, opacity: 1});
                animate.to(self.querySelector('.cta'), {y: 0, opacity: 1});
            },
            '(max-width: 767px)': function () {
                var animate = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: '25% center',
                        end: 'center 20%',
                        pin: false,
                        scrub: true,
                        once: true
                    }
                });

                animate.to(self.querySelector('.heading-h2'), {y: 0, opacity: 1});
                animate.to(self.querySelector('p'), {y: 0, opacity: 1});
                animate.to(self.querySelector('ul'), {y: 0, opacity: 1});
                animate.to(self.querySelector('.cta'), {y: 0, opacity: 1});
            }
        });
    });

    var motionAnimation = Array.prototype.slice.call(document.querySelectorAll('.block_image_motion.animation'));

    motionAnimation.forEach(function (self) {
        ScrollTrigger.matchMedia({
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top 80%',
                        end: 'center 60%',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('.motion') && !self.querySelector('video')) {
                    tl.to(self.querySelector('.motion'), {x: 0, opacity: 1});
                }

                if (self.querySelector('video')) {
                    tl.to(self.querySelector('video'), {x: 0, opacity: 1});
                }
            },
            '(max-width: 1023px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top center',
                        end: '+=500',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('.motion') && !self.querySelector('video')) {
                    tl.to(self.querySelector('.motion'), {x: 0, opacity: 1});
                }

                if (self.querySelector('video')) {
                    tl.to(self.querySelector('video'), {x: 0, opacity: 1});
                }
            }
        });
    });

    var imageTextBigAnimation = Array.prototype.slice.call(document.querySelectorAll('.block_image_text_big.animation'));

    imageTextBigAnimation.forEach(function (self) {
        ScrollTrigger.matchMedia({
            // desktop eerst 100px omhoog en dan 200px naar beneden
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top center -=200px',
                        end: '75% center',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('.image') && !self.querySelector('video')) {
                    tl.to(self.querySelector('.image'), {opacity: 1, y: 30});
                }

                if (self.querySelector('video')) {
                    tl.to(self.querySelector('video'), {opacity: 1, y: 30});
                }
            },
            '(max-width: 1023px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: '20% center',
                        end: '60% center',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('.image') && !self.querySelector('video')) {
                    tl.to(self.querySelector('.image'), {opacity: 1});
                }

                if (self.querySelector('video')) {
                    tl.to(self.querySelector('video'), {opacity: 1});
                }
            }
        });
    });

    var collega = Array.prototype.slice.call(document.querySelectorAll('.block_collage'));

    collega.forEach(function (self) {
        ScrollTrigger.matchMedia({
            // desktop
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: '10% center',
                        end: '75% center',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('.top')) {
                    tl.to(self.querySelector('.top'), {y: 0, opacity: 1, duration: 6});
                }

                if (self.querySelector('.bottom')) {
                    tl.to(self.querySelector('.bottom'), {x: 0, opacity: 1, duration: 6});
                }
            },
            '(max-width: 1023px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top center',
                        end: 'center center',
                        pin: false,
                        scrub: true
                    }
                });

                if (self.querySelector('.top')) {
                    tl.to(self.querySelector('.top'), {y: 0, opacity: 1, duration: 6});
                }

                if (self.querySelector('.bottom')) {
                    tl.to(self.querySelector('.bottom'), {x: 0, opacity: 1, duration: 10});
                }
            }
        });
    });

    var imageThreeColumnsAnimation = Array.prototype.slice.call(document.querySelectorAll('.block_image_three_columns.animation'));

    imageThreeColumnsAnimation.forEach(function (self) {
        ScrollTrigger.matchMedia({
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top 25%',
                        end: 'bottom 35%',
                        scrub: true,
                        pin: false
                    }
                });

                tl.to(self.querySelector('.overlay'), {height: 3000});
                tl.to(self.querySelector('.grid-container'), {y: 50});
            },
            '(min-width: 768px) and (max-width: 1023px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top bottom',
                        end: 'bottom 35%',
                        scrub: true,
                        pin: false,
                    }
                });

                tl.to(self.querySelector('.grid-container'), {y: 50});
                tl.to(self.querySelector('.overlay'), {height: 3000});
            },
            '(max-width: 767px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top 25%',
                        end: '25% 25%',
                        scrub: true,
                        pin: false
                    }
                });

                tl.to(self.querySelector('.overlay'), {height: 3500});
            }
        });
    });

    var imageFourColumns = Array.prototype.slice.call(document.querySelectorAll('.block_image_four_columns.has_animation'));

    imageFourColumns.forEach(function (self) {
        ScrollTrigger.matchMedia({
            '(min-width: 1024px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: 'top center',
                        end: 'center center',
                        scrub: true,
                        pin: false,
                        once: true
                    }
                });

                tl.to(self.querySelectorAll('.item'), {
                    opacity: 1,
                    stagger: 0.4
                })
            },
            '(min-width: 768px) and (max-width: 1023px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: '5% center',
                        end: '55% center',
                        scrub: true,
                        pin: false,
                        once: true,
                    }
                });

                tl.to(self.querySelectorAll('.item'), {
                    opacity: 1,
                    stagger: 0.4
                })
            },
            '(max-width: 767px)': function () {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: self,
                        start: '5% center',
                        end: '60% center',
                        scrub: true,
                        pin: false,
                        once: true
                    }
                });

                tl.to(self.querySelectorAll('.item'), {
                    opacity: 1,
                    stagger: 0.4
                })
            }
        });
    });

    var imageVideoVideo = Array.prototype.slice.call(document.querySelectorAll('.block_image_video video'));

    imageVideoVideo.forEach(function (self) {
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: self,
                start: 'top 80%',
                end: 'bottom 60%',
                scrub: true,
                pin: false,
                onEnter: () => {
                    self.play();
                },
                onEnterBack: () => {
                    self.play();
                },
                onLeave: () => {
                    self.pause();
                },
                onLeaveBack: () => {
                    self.pause();
                },
            }
        });
    });

    var imageVideoContent = Array.prototype.slice.call(document.querySelectorAll('.block_image_video .content'));

    imageVideoContent.forEach(function (self) {
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: self,
                start: '-30% 80%',
                end: 'top bottom',
                scrub: true,
                pin: false,
                once: true
            }
        });

        tl.to(self, {
            opacity: 1,
        })
    });
});
